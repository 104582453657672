import React from 'react'

import Header from '../../components/header/header';


function Alunos() {
  return (
    <div className="alunos">

        <Header />

        <section className="section-wrapper">
          <div className="column-fixed">
            <h1 className="title1">A indisciplina promove más avaliações escolares, mudar comportamentos é ter sucesso! <br/> <span className="title2">Estamos no GC+ para te ajudar a alcançar o sucesso.</span></h1>
          </div>
          
          <div className="column-sliding">
            <div className="row">
              <div className="circle"><span className="circle-structure"></span></div>
              <div className="text-block">
                <h1 className="subtitle">Quando me posso dirigir ao GC+?</h1>
                <p className="text">Podes dirigir-te ao GC+ sempre que quiseres ou necessitares dentro dos horários estabelecidos pelos mesmos. <span className="bold">Todo e qualquer aluno é bem vindo ao GC+</span>.</p>
              </div>
            </div>
            <div className="row">
              <div className="circle"><span className="circle-structure"></span></div>
              <div className="text-block">
                <h1 className="subtitle">Há outras maneiras de comunicar com o GC+?</h1>
                <p className="text">Coloca as tuas dúvidas/questões/problemas na <span className="bold">caixa de correio que está na sala do aluno (JB) com o teu número, ano e turma</span>. Se preferires, um dos professores do Gabinete irá ter contigo.</p>
              </div>
            </div>
            <div className="row">
              <div className="circle"><span className="circle-structure"></span></div>
              <div className="text-block">
                <h1 className="subtitle">Quem se pode dirigir ao gabinete GC+?</h1>
                <p className="text">Todos os alunos que necessitem melhorar a sua conduta em ambiente escolar, que tenham sido vítimas ou testemunhas de situações de violência escolar ou que tenham dificuldades de integração, bem como outras situações de âmbito pessoal ou por iniciativa individual;</p>
              </div>
            </div>
            <div className="row">
              <div className="circle"><span className="circle-structure"></span></div>
              <div className="text-block">
                <h1 className="subtitle">Onde se situa o GC+?</h1>
                <p className="text">O Gabinete Cidadão+ da Escola <span className="bold">Camilo Castelo Branco</span> situa-se no terceiro andar (junto à sala de trabalho dos professores).</p>
                <p className="text">O Gabinete Cidadão+ da Escola <span className="bold">Júlio Brandão</span> situa-se na sala 4.8.</p>
              </div>
            </div>
          </div>
        </section>

        
        <section className="section-wrapper2">     

          <div className="column-fixed">
            <h1 className="title1">Qual o comportamento adequado na sala de aula?<br/> <span className="title2">Aqui estão algumas regras...</span></h1>
          </div>

          <div className="column-sliding2">
            <div className="row">
              <div className="circle"><span className="circle-structure2"></span></div>
              <div className="text-block">
                <h1 className="subtitle">Ser assíduo/a e pontual.</h1>
              </div>
            </div>
            <div className="row">
              <div className="circle"><span className="circle-structure2"></span></div>
              <div className="text-block">
                <h1 className="subtitle">Trazer o material necessário para a aula.</h1>
              </div>
            </div>
            <div className="row">
              <div className="circle"><span className="circle-structure2"></span></div>
              <div className="text-block">
                <h1 className="subtitle">Contribuir para a criação de um ambiente de respeito mútuo entre alunos e professores.</h1>
              </div>
            </div>
            <div className="row">
              <div className="circle"><span className="circle-structure2"></span></div>
              <div className="text-block">
                <h1 className="subtitle">Pedir permissão para falar e esperar a sua vez para intervir.</h1>
              </div>
            </div>
            <div className="row">
              <div className="circle"><span className="circle-structure2"></span></div>
              <div className="text-block">
                <h1 className="subtitle">Participar ordeira e oportunamente nas atividades da aula.</h1>
              </div>
            </div>
            <div className="row">
              <div className="circle"><span className="circle-structure2"></span></div>
              <div className="text-block">
                <h1 className="subtitle">Falar baixo e utilizar uma linguagem adequada, demonstrando boa educação.</h1>
              </div>
            </div>
            <div className="row">
              <div className="circle"><span className="circle-structure2"></span></div>
              <div className="text-block">
                <h1 className="subtitle">Estar atento/a às atividades da aula, às indicações dos professores e às intervenções dos colegas.</h1>
              </div>
            </div>
            <div className="row">
              <div className="circle"><span className="circle-structure2"></span></div>
              <div className="text-block">
                <h1 className="subtitle">Seguir as orientações dos professores relativas ao trabalho a realizar, no âmbito do processo ensino-aprendizagem.</h1>
              </div>
            </div>
            <div className="row">
              <div className="circle"><span className="circle-structure2"></span></div>
              <div className="text-block">
                <h1 className="subtitle">Contribuir para o bom funcionamento da aula, evitando conversas paralelas ou comentários impertinentes.</h1>
              </div>
            </div>
            <div className="row">
              <div className="circle"><span className="circle-structure2"></span></div>
              <div className="text-block">
                <h1 className="subtitle">Respeitar as opiniões e os gostos dos outros. Saber ouvir.</h1>
              </div>
            </div>
            <div className="row">
              <div className="circle"><span className="circle-structure2"></span></div>
              <div className="text-block">
                <h1 className="subtitle">Solicitar esclarecimento de dúvidas, quando necessário.</h1>
              </div>
            </div>
            <div className="row">
              <div className="circle"><span className="circle-structure2"></span></div>
              <div className="text-block">
                <h1 className="subtitle">Fazer sugestões que contribuam para incentivar o interesse pela disciplina.</h1>
              </div>
            </div>
            <div className="row">
              <div className="circle"><span className="circle-structure2"></span></div>
              <div className="text-block">
                <h1 className="subtitle">Não utilizar telemóvel ou qualquer outro tipo de equipamento multimédia pessoal que possa perturbar a aula.</h1>
              </div>
            </div>
            <div className="row">
              <div className="circle"><span className="circle-structure2"></span></div>
              <div className="text-block">
                <h1 className="subtitle">Não danificar o material escolar, designadamente, não escrever nas paredes, nos parapeitos nem em qualquer outro equipamento escolar, no interior da sala de aula.</h1>
              </div>
            </div>
            <div className="row">
              <div className="circle"><span className="circle-structure2"></span></div>
              <div className="text-block">
                <h1 className="subtitle">Respeitar a autoridade dos professores.</h1>
              </div>
            </div>
            <div className="row">
              <div className="circle"><span className="circle-structure2"></span></div>
              <div className="text-block">
                <h1 className="subtitle">Adotar uma postura e um comportamento corretos.</h1>
              </div>
            </div>
            <div className="row">
              <div className="circle"><span className="circle-structure2"></span></div>
              <div className="text-block">
                <h1 className="subtitle">Utilizar as normas de boa educação e as formas corretas de tratamento na relação aluno/a -professor/a e aluno/a - aluno/a.</h1>
              </div>
            </div>
            <div className="row">
              <div className="circle"><span className="circle-structure2"></span></div>
              <div className="text-block">
                <h1 className="subtitle">Entrar e sair, ordeiramente, da sala de aula.</h1>
              </div>
            </div>
          </div>

        </section>

    </div>
  );
}

export default Alunos;