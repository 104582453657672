import React from 'react'
import './header.css';

import Dropdown from '../dropdown/dropdown';
import { Link} from 'react-router-dom'
import { useLocation } from 'react-router-dom';

function Header() {
//assigning location variable
  const location = useLocation();

//destructuring pathname from location
  const { pathname } = location;

//Javascript split method to get the name of the path in array
  const splitLocation = pathname.split("/");
  return (
    <div className="header">
        
        <div className="menu-wrapper">
            <ul id="homepage-menu" className="homepage-menu">
                {splitLocation[1] === "" ? "" : 
                    <li className={splitLocation[1] === "" ? "active" : ""}>
                        <Link to="/" >Página Inicial</Link>
                    </li>
                }
                <li className={splitLocation[1] === "alunos" ? "active" : ""}>
                    <Link to="/alunos" >Alunos</Link>
                </li>
                <li className={splitLocation[1] === "encarregados" ? "active" : ""}>
                    <Link to="/encarregados" >Encarregados de Educação</Link>
                </li>
                <li className={splitLocation[1] === "professores" ? "active" : ""}>
                    <Link to="/professores" >Professores</Link>
                </li>
                <li className={splitLocation[1] === "gabinete" ? "active" : ""}>
                    <Link to="/gabinete" >Gabinete do Cidadão</Link>
                </li>
            </ul>
        </div>

        <div className="secundary-menu">
            <Dropdown className="menu" />
        </div>

    </div>
  );
}

export default Header;
