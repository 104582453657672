import React from 'react'
import BackgroundSlider from 'react-background-slider';
import {motion} from 'framer-motion';

import './homepage.css';
import Header from '../../components/header/header';

import background1 from '../../images/background1.jpg'
import background2 from '../../images/background2.jpg'
import aeccb from '../../images/aeccb-logo.png'
import gabinete from '../../images/gabinete.png'
import julio from '../../images/julio.png'

function Homepage() {
  return (
    <div className="homepage">

      <div className="homepage-background-image">
        <BackgroundSlider
          images={[background1, background2]}
          duration={10} transition={2} />
      </div>

      <Header className="header"/>

 
      <div className="gabinete-title" >
        <p> Gabinete do Cidadão+ </p>
      </div>


      <div className="homepage-menu-wrapper">
        <ul className="homepage-menu-vertical">
          <motion.p
            initial={{ opacity:0, y: -50 }}
            animate={{ opacity:1, y: 0 }}
            transition={{ duration:1.5 }}
            alt='' className='juntos-mobile'> Juntos, a construir o futuro! </motion.p>

        </ul>
      </div>
    
      <div className="footer-wrapper">
          <img src={aeccb} alt='' className="logo"/>
          <img src={julio} alt='' className="logo"/>
          <img src={gabinete} alt='' className="logo"/>
          <h1  className="juntos">Juntos, a construir o futuro!</h1>
      </div>



    </div>
  );
}

export default Homepage;
