import React from 'react'

import Header from '../../components/header/header';


function Encarregados() {
  return (
    <div className="alunos">

        <Header />

        <section className="section-wrapper">
          <div className="column-fixed">
            <h1 className="title1">Compete ao <br/> <span className="title2">Gabinete do Cidadão+</span></h1>
          </div>
          
          <div className="column-sliding">
            <div className="row">
              <div className="circle"><span className="circle-structure"></span></div>
              <div className="text-block">
                <h1 className="subtitle">Educar para os valores da cidadania, promovendo o desenvolvimento de uma consciência cívica que integre valores de dimensão universal, como o respeito pelo outro, a tolerância, a liberdade, a democracia, a solidariedade e os direitos humanos;</h1>
              
              </div>
            </div>
            <div className="row">
              <div className="circle"><span className="circle-structure"></span></div>
              <div className="text-block">
                <h1 className="subtitle">Reduzir os casos de indisciplina, dentro e fora da sala de aula;</h1>
              </div>
            </div>

            <div className="row">
              <div className="circle"><span className="circle-structure"></span></div>
              <div className="text-block">
                <h1 className="subtitle">Prevenir o absentismo e o abandono escolar;</h1>
              </div>
            </div>

            
            <div className="row">
              <div className="circle"><span className="circle-structure"></span></div>
              <div className="text-block">
                <h1 className="subtitle">Promover os direitos a proteção dos alunos do AECCB, em geral, e dos que se encontrem em situação de risco ou perigo, em particular.</h1>
              </div>
            </div>

            <div className="row">
              <div className="text-block">
                <h4 className="subtitle">Regulamento Interno AECCB</h4>
              </div>
            </div>

          </div>
        </section>

    </div>
  );
}

export default Encarregados;