import React from 'react'

import Header from '../../components/header/header';


function Professores() {
  return (
    <div className="alunos">

        <Header />

        <section className="section-wrapper3">
          <div className="one-column1">
            <h1 className="title1">Encaminhamento de alunos<br/> <span className="title2">Gabinete Cidadão+</span></h1>
          </div>
          
          <div className="one-column2">
            <div className="row">
              <div className="text-block">
                <div className="flex">
                  <div className="circle"><span className="circle-structure"></span></div>
                  <h1 className="subtitle1">Se o Professor considerar que o aluno manifestou um comportamento incorreto ou outros motivos válidos (vítimas ou testemunhas de situações de violência escolar, que tenham dificuldades de integração, entre outros) <span className="bold">deverá solicitar ao assistente operacional que acompanhe o aluno até ao GC+</span>. O aluno <span className="bold">deve levar a ficha “Participação de Ocorrência”</span> para apresentar ao elemento do GC+ que o irá atender.</h1>
                </div>
                
                <div className="flex">
                  <div className="circle"><span className="circle-structure"></span></div>
                  <h1 className="subtitle1">Sempre que uma situação de indisciplina ou agressividade se verifique fora da sala de aula, o assistente operacional ou o professor que presencie a situação <span className="bold">deverá acompanhar o aluno ao GC+ ou à Direção e preencher a ficha “Participação de Ocorrência”</span>.</h1>
                </div>
               
                <div className="flex">
                  <div className="circle"><span className="circle-structure"></span></div>
                  <h1 className="subtitle1">A ficha de “Participação de Ocorrência” poderá ser solicitada à <span className="bold">assistente operacional de cada um dos blocos</span> (JB) ou em <span className="bold">cada um dos corredores</span> (CCB).</h1>
                </div>
                
              </div>
            </div>
     

          </div>
        </section>

        
        

    </div>
  );
}

export default Professores;