import React from 'react'

import Header from '../../components/header/header';


function Gabinete() {
  return (
    <div className="alunos">

        <Header />

        <section className="section-wrapper3">
          <div className="one-column1">
            <h1 className="title1">Localização dos <br/> <span className="title2">Gabinetes do Cidadão+</span></h1>
          </div>
          
          <div className="one-column2">
            <div className="row">
              <div className="text-block">

                <div className="flex">
                  <div className="circle"><span className="circle-structure"></span></div>
                  <div>
                    <h1 className="subtitle1">O Gabinete Cidadão+ da Escola <b className="bold">Camilo Castelo Branco</b> situa-se no terceiro andar (junto à sala de trabalho dos professores).</h1>
                    <h2 className="subtitle1">Coordenadora do Gabinete Cidadão+ CCB:</h2>
                    <h3 className="subtitle1"><b className="bold">Fátima Costa – 362@aeccb.pt</b></h3>
                  </div>
                </div>
                
                <div className="flex">
                  <div className="circle"><span className="circle-structure"></span></div>
                  <div>
                    <h1 className="subtitle1">O Gabinete Cidadão+ da Escola <b className="bold">Júlio Brandão</b> situa-se na sala 4.8.</h1>
                    <h2 className="subtitle1">Coordenadora do Gabinete Cidadão+ JB:</h2>
                    <h3 className="subtitle1"><b className="bold">Amália Zamith – 1956@aeccb.pt</b></h3>
                  </div>
                </div>
               
              </div>
            </div>
     

          </div>
        </section>

        <section className="section-wrapper4">     
          <div className="column-fixed">
            <h1 className="title1">Compete aos professores da equipa <span className="title2">Gabinete Cidadão+</span></h1>
          </div>
          
          <div className="column-sliding2">
            <div className="row">
              <div className="circle"><span className="circle-structure2"></span></div>
              <div className="text-block">
                <h1 className="subtitle">Tomar conhecimento das ocorrências disciplinares e procurar soluções para os planos de indisciplina e violência na escola, em articulação com outras estruturas</h1>
              
              </div>
            </div>
            <div className="row">
              <div className="circle"><span className="circle-structure2"></span></div>
              <div className="text-block">
                <h1 className="subtitle">Dinamizar atividades que tenham uma ação preventiva na manutenção do bom ambiente da escola, em articulação com outras estruturas do agrupamento</h1>
              </div>
            </div>
            <div className="row">
              <div className="circle"><span className="circle-structure2"></span></div>
              <div className="text-block">
                <h1 className="subtitle">Articular estratégias comuns com todos os docentes que integram o Gabinete do Cidadão+</h1>
              </div>
            </div>
          
          </div>
        </section>

        
        <section className="section-wrapper2">     
          <div className="column-fixed">
            <h1 className="title1">Funcionamento do <br/> <span className="title2">Gabinete Cidadão+</span></h1>
            <h1 className="subtitle">No seguimento do encaminhamento feito pelo professor ou pelo assistente operacional, o elemento que se encontra disponível no gabinete irá:</h1>
          </div>
          
          <div className="column-sliding2">
            <div className="row">
              <div className="circle"><span className="circle-structure2"></span></div>
              <div className="text-block">
                <h1 className="subtitle">Acolher os alunos para aí encaminhados</h1>
              </div>
            </div>
            <div className="row">
              <div className="circle"><span className="circle-structure2"></span></div>
              <div className="text-block">
                <h1 className="subtitle">Procurar mediar e gerir os conflitos existentes, fazendo com que os alunos tomem consciência do impacto negativo da sua atitude e comportamento no normal funcionamento das aulas, no seu relacionamento com os outros e na sua aprendizagem</h1>
              </div>
            </div>
            <div className="row">
              <div className="circle"><span className="circle-structure2"></span></div>
              <div className="text-block">
                <h1 className="subtitle">Registar sucintamente o relato dos acontecimentos e a atitude do aluno nos documentos adequados</h1>
              </div>
            </div>
            <div className="row">
              <div className="circle"><span className="circle-structure2"></span></div>
              <div className="text-block">
                <h1 className="subtitle">Fotocopiar e arquivar a cópia da ficha “Participação de Ocorrência” no respetivo dossier e entregar o documento original, na data da ocorrência, ao diretor de turma que, por sua vez, entrará em contacto com o encarregado de educação</h1>
              </div>
            </div>
            <div className="row">
              <div className="circle"><span className="circle-structure2"></span></div>
              <div className="text-block">
                <h1 className="subtitle">Promover atitudes e comportamentos de bom relacionamento interpessoal com todos os membros da comunidade educativa, salientados os seus deveres cívicos, pautados por um sistema de valores orientado para a harmonia e interajuda entre todos</h1>
              </div>
            </div>
            <div className="row">
              <div className="circle"><span className="circle-structure2"></span></div>
              <div className="text-block">
                <h1 className="subtitle">Fazer o encaminhamento das diferentes situações, sempre que necessário, para os diferentes serviços de apoio dos alunos com dificuldades de integração escolar</h1>
              </div>
            </div>
            <div className="row">
              <div className="circle"><span className="circle-structure2"></span></div>
              <div className="text-block">
                <h1 className="subtitle">Em situações de maior gravidade, a Coordenadora do Gabinete deverá ser informada e contactará o Diretor do Agrupamento (ou o seu representante), que providenciará uma atuação disciplinar rápida e eficaz</h1>
              </div>
            </div>
            <div className="row">
              <div className="text-block">
                <h1 className="subtitle">Nota: o aluno não deve permanecer no Gabinete por um período superior a quarenta e cinco minutos, a não ser que a situação o justifique.</h1>
              </div>
            </div>
          </div>
        </section>

    </div>
  );
}

export default Gabinete;