import React, { useRef } from "react";
import "./dropdown.css";
import { useDetectOutsideClick } from "./useDetectOutsideClick";
import menu  from '../../images/menu.svg'
import { useLocation } from "react-router-dom";

/*
 * Read the blog post here:
 * https://letsbuildui.dev/articles/building-a-dropdown-menu-component-with-react-hooks
 */
export default function Dropdown() {
  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useDetectOutsideClick(false);
  const onClick = () => setIsActive(!isActive);
  const location = useLocation();

  //destructuring pathname from location
  const { pathname } = location;

//Javascript split method to get the name of the path in array
  const splitLocation = pathname.split("/");

  return (
    <div className="container">
      <div className="menu-container">
        <button onClick={onClick} className="menu-trigger">
          <img
            src={menu}
            alt="User avatar"
          />
        </button>
        <nav
          ref={dropdownRef}
          className={`menu ${isActive ? "active" : "inactive"}`}
        >
          <ul>
          { splitLocation[1] === "" ? "" : 
            <li>
              <a href="/">Página Inicial</a>
            </li>}
            <li>
              <a className={splitLocation[1] === "alunos" ? "active" : ""} href="/alunos">Alunos</a>
            </li>
            <li>
              <a className={splitLocation[1] === "encarregados" ? "active" : ""} href="/encarregados">Encarregados de Educação</a>
            </li>
            <li>
              <a  className={splitLocation[1] === "professores" ? "active" : ""} href="/professores">Professores</a>
            </li>
            <li>
              <a className={splitLocation[1] === "gabinete" ? "active" : ""} href="/gabinete">Gabinete do Cidadão</a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}
