import React from 'react'
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Homepage from './pages/homepage/homepage';
import Alunos from './pages/alunos/alunos';
import Encarregados from './pages/encarregados/encarregados';
import Professores from './pages/professores/professores';
import Gabinete from './pages/gabinete/gabinete';

function App() {
  return (
    <BrowserRouter>
        <Routes>
          <Route path="/" element={<Homepage/>} exact />
          <Route path="/alunos" element={<Alunos/>} />
          <Route path="/encarregados" element={<Encarregados/>} />
          <Route path="/professores" element={<Professores/>} />
          <Route path="/gabinete" element={<Gabinete/>} />
        </Routes>
    </BrowserRouter>
  );
} 

export default App;
